
































































































import { mapGetters } from 'vuex'
import wAxios from '@/plugins/w/axios'

const api = {
	exams: (params) => wAxios.get_auth('/student/exams', { params })
}

export default {
	name: 'Profile',

	components: {
		'a-navbar': () => import('@/plugins/app@components/navbar/a-navbar.vue'),
		'z-loader': () => import('@/plugins/app@components/loader/z-loader.vue'),
		'a-private-field': () => import('@/plugins/app@components/private-field/a-private-field.vue'),
		'a-exam-results': () => import('@/plugins/app@components/exam/a-exam-results.vue')
	},

	data() {
		return {
			activeSegment: this.$route.params.tab || 'info',
			loading: false,
			editing: false,
			exams: [],
			currentPage: 0,
			endOfList: false
		}
	},

	computed: {
		...mapGetters('group', [ 'getGroupInvites' ]),
		...mapGetters('wAuth', [ 'user' ]),
		initials: function() {
			return `${this.user.name[0] || ''}+${this.user.surname && this.user.surname[0] || ''}`
		}
	},

	async mounted() {
		await this.$store.dispatch('group/fetchGroups')
	},

	watch: {
		activeSegment: {
			handler: function(newValue) {
				history.replaceState(
					null,
					newValue,
					`${location.origin}${this.$route.matched[0].path.replace(':tab?', newValue)}`
				)
				if (newValue == 'historia') {
					this.loadExams()
				}
			},
			immediate: true
		}
	},

	methods: {

		async editProfile() {
			if (this.editing) {
				try {
					this.loading = true
					await this.$store.dispatch('wAuth/updateUserInfo', { name: this.user.name, surname: this.user.surname })
				} catch (err) {
					this.$wToast.error(err)
				} finally {
					this.loading = false
				}
			}
			this.editing = !this.editing
		},

		async loadExams(event) {
			if (this.endOfList) {
				return
			}

			try {
				this.loading = true
				const { data: { data: exams, meta } } = await api.exams({ page: ++this.currentPage })
				this.exams = this.exams.concat(exams)
				if (meta.last_page == this.currentPage) {
					this.endOfList = true
				}
				if (event) {
					event.target.complete()
				}
			} catch (err) {
				console.error(err)
			} finally {
				this.loading = false
			}
		},

		async expandExam(i) {
			this.exams.forEach((e, index) => { if (index != i) { e.expanded = false } })
			this.$set(this.exams, i, { ...this.exams[i], expanded: !this.exams[i].expanded })
			// wait for expanding
			await this.$nextTick()
			// wait for animation transition set in sass
			await new Promise((r) => setTimeout(r, 500))
			this.$refs['content'].scrollToPoint(0, this.$refs['exam-item' + i][0].offsetTop - 10, 1000)
		},

		goToHistoryDetail(exam) {
			this.$router.push({ name: 'ExamHistoryDetail', params: {
				examhash: exam.exam.hash,
				id: exam.id
			} })
		},

		selectSegment(event) {
			this.activeSegment = event.detail.value
		}
	}
}
